import {registerApplication, start, unregisterApplication} from "single-spa";
import {constructApplications, constructLayoutEngine, constructRoutes,} from "single-spa-layout";
import {BehaviorSubject} from "rxjs";
import acesso from './microfrontend-layout-acesso.html';
import funcionario from './microfrontend-layout-funcionario.html';
import cliente from './microfrontend-layout-cliente.html';
import {LayoutEngine} from "single-spa-layout/dist/types/browser/constructLayoutEngine";

interface Auth {
    token?: string,
    user?: any,
    logout?: () => any;
    isCliente?: boolean;
    isParceiro?: boolean;
}

interface Tab {
    label: string,
    route?: string,
    icon?: string,
    badge?: number,
}

const context = {
    loading: new BehaviorSubject<boolean>(false),
    session: new BehaviorSubject<Auth>({}),
    tabs: new BehaviorSubject<Array<Tab>>([]),
};

function mapLayout(layout: string | Element) {
    const routes = constructRoutes(layout, {
        loaders: [],
        props: {
            context
        }
    });

    const applications = constructApplications({
        routes: routes,
        loadApp({name}) {
            return System.import(name);
        },
    });

    const layoutEngine = constructLayoutEngine({
        routes: routes,
        applications: applications,
    });

    applications.forEach(registerApplication);

    return {layoutEngine, applications: applications.map(a => a.name)};
}

mapLayout(acesso);

let currentCondition: string;
let currentLayout: { layoutEngine: LayoutEngine, applications: any[] };

function identifyCondition(sessao: Auth) {
    if (!!sessao.token) {
        if (sessao.isCliente) {
            return 'cliente';
        } else if (sessao.isParceiro) {
            return 'parceiro';
        }

        return 'funcionario';
    }

    return 'signout';
}

context.session.subscribe(session => {
    let newCondition = identifyCondition(session);

    if (newCondition !== currentCondition) {
        currentLayout?.applications?.forEach(unregisterApplication);
        currentLayout?.layoutEngine?.deactivate();

        switch (newCondition) {
            case 'funcionario':
                currentLayout = mapLayout(funcionario);
                break;
            case 'cliente':
                currentLayout = mapLayout(cliente);
                break;
        }

        currentCondition = newCondition;
    }

});

start();
